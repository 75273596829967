

import google from '../google.svg'
import appstore from '../appStore.svg'
import youtube from '../Vector-1.svg'
import tiktok from '../Vector-2.svg'
import facebook from '../Vector.svg'
import instagram from '../Group.svg'
import X from '../layer1.svg'


const Footer2 = () => {

   return (
    <div className="flex flex-col xs:flex-row bg-black h-[20vh] pb-32 xs:pb-0 xs:mt-0 pt-8  items-center">
         
    <div className="flex w-[50%] flex-row justify-center xs:justify-start xs:pl-[12%] space-x-2">
     
    </div>
 
   <div className='flex flex-row justify-center mt-4  xs:justify-end w-[50%] xs:pr-[12%] space-x-3'>
   <a href='https://www.facebook.com/blackwallet.ar' target='blank'> <img src={facebook} className='w-6 h-6'/> </a>
               <a href='https://www.instagram.com/blackwallet.ar/' target='blank'> <img src={instagram} className='w-6 h-6'/> </a> 
               <a href='https://twitter.com/Blackwallet_ar' target='blank'> <img src={X} className='w-6 h-6'/> </a> 
               <a href='https://www.youtube.com/@blackwallet_ar' target='blank'> <img src={youtube} className='w-6 h-6'/></a>
               <a href='https://www.tiktok.com/@blackwallet.ar' target='blank'> <img src={tiktok} className='w-6 h-6'/> </a>
   </div>
    

</div>
   )
}

export default Footer2