

import { Link } from 'react-router-dom'
import Hero from '../../Components/Clubes/Hero'
import venus from '../../Venus.svg'
import Comunidad from '../../Components/Comunidad'
import Footer2 from '../../Components/Footer2'

const Banfield = () => {
    return (
       
        <div className='bg-black'>

                <Link to='/' className='bg-black z-[100] py-10 flex top-0  w-full justify-center'>
                <img src={venus} className=' w-[25%] xs:w-[11%] lt:w-[8%]'/>
                </Link>
            
        
            <Hero 
            titulo='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693492910/WALLET%20BLACK/Landing%20beneficios/Hero/escudo-banfield-transparencia_rvv7tc.png'
             descripcion='Ser de El Taladro ahora tiene beneficios exclusivos, suscribite a
             la Comunidad Black y enterate de todos los descuentos y promociones
             que tenemos para vos!'
             titleForm='¡Unite a la comunidad de Banfield!'
             logo={'https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693491885/WALLET%20BLACK/Landing%20beneficios/Hero/CLUB_ATL%C3%89TICO_BANFIELD_risfae.svg'}
            />


            <Comunidad
            camiseta={'https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693580115/WALLET%20BLACK/Landing%20beneficios/Comunidad/banfield/camiseta_banfield-04_s0ej9a.png'}
            entrada={'https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693582831/WALLET%20BLACK/Landing%20beneficios/Comunidad/banfield/entradas3d-_mockup-02_1_k93r9k.png'}
            top='top-[-25%] xs:top-[-35%] xl:top-[-54%]'
           />

        <Footer2/>

        </div>

    )

}


export default Banfield