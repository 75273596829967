import { Link } from 'react-router-dom'
import Hero from '../../Components/Clubes/Hero'
import venus from '../../Venus.svg'

import { useMediaQuery } from "react-responsive";
import Comunidad from '../../Components/Comunidad';
import Footer2 from '../../Components/Footer2';
import Formulario from '../../Components/Form';

const Santamarina = () => {

  const isMobile = useMediaQuery({ maxWidth: 480 }); // Para solo smartphones

    return (
        <div className='bg-black'>
          <Link to='/' className='bg-black z-[100] py-10 flex top-0  w-full justify-center'>
                <img src={venus} className=' w-[25%] xs:w-[8%]'/>
                </Link>
      <div className="bg-black flex animate-fadeInDown flex-col xs:flex-row">
    
      <div className="w-full xs:w-[50%]  relative  flex justify-center  items-start ">
        <img
          className="w-[52%] pr-[2%] pt-[4%]  xs:w-[60%] lt:w-[59%] md:w-[54%] xs:pt-[10%] lt:pt-[3%] xs:pr-[10%]"
          src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693506097/WALLET%20BLACK/Landing%20beneficios/Hero/Santamarina/Group_515_zo8n0h.svg'
          />

        <div className="absolute top-[20%] left-[12%] w-[75%] xs:w-auto xs:top-[25%] xs:left-[15%]">
          <img 
          className="w-full xs:w-auto"
          src={isMobile ? 'https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693573649/WALLET%20BLACK/Landing%20beneficios/Hero/Rosario/Group_519_catfqw.svg' : 
          'https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693506047/WALLET%20BLACK/Landing%20beneficios/Hero/Santamarina/CLUB_SANTAMARINA_mpcrvh.svg'} />
        
           {isMobile ? null : 
           <p className="text-white text-[15px] lt:text-[19px] leading-5 mt-[8%] w-[100%] lt:w-[75%]">
            Alentar al Santa nunca fue tan fácil! Suscribite a la Comunidad Black y enterate de todos los descuentos y promociones que tenemos para vos!
            </p>} 
        </div>
      </div>

     {isMobile ? 
        <div className=" flex justify-center items-center">
        <p className="text-white text-[16px] font-medium leading-5 mt-[5%] w-[80%] ">Alentar al Santa nunca fue tan fácil! Suscribite a la Comunidad Black y
         enterate de todos los descuentos y promociones que tenemos para vos!
            </p>
         </div> 
            : null}
     
      <Formulario title={'¡Unite a la comunidad de SantaMarina!'} Comunidad='santaMarina' />

    </div>

       <Comunidad 
       camiseta='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693584128/WALLET%20BLACK/Landing%20beneficios/Comunidad/banfield/redimension_Mesa_de_trabajo_1_1_u28tzj.png'
       entrada='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693584222/WALLET%20BLACK/Landing%20beneficios/Comunidad/banfield/entradas3d-_mockup-03_k7vreu.png'
       top='top-[-20%] xs:top-[-28%] lt:top-[-40%] xl:top-[-65%]'
       />

    <Footer2/>
              </div>
    )

}


export default Santamarina