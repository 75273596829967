import { Link } from 'react-router-dom'
import Hero from '../../Components/Clubes/Hero'
import venus from '../../Venus.svg'
import { useMediaQuery } from 'react-responsive'
import Comunidad from '../../Components/Comunidad'
import Footer2 from '../../Components/Footer2'
import Formulario from '../../Components/Form'

const Rosario = () => {
 
  const isMobile = useMediaQuery({ maxWidth: 480 }); // Para solo smartphones

  return (
       
      <div className='bg-black'>
      <Link to='/' className='bg-black z-[100] py-10 flex top-0  w-full justify-center'>
            <img src={venus} className=' w-[25%] xs:w-[8%]'/>
            </Link>
  <div className="bg-black flex animate-fadeInDown flex-col xs:flex-row">

  <div className="w-full xs:w-[50%]  relative  flex justify-center  items-start ">
    <img
      className="w-[52%] pr-[2%] pt-[4%]  xs:w-[65%] lt:w-[59%] md:w-[59%] xs:pt-[10%] lt:pt-[3%] xs:pr-[10%]"
      src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693505446/WALLET%20BLACK/Landing%20beneficios/Hero/Rosario/Group_515_1_ympjqe.svg'
      />

    <div className="absolute top-[24%] left-[5%] w-[90%] xs:w-auto xs:top-[20%] md:top-[25%] xs:left-[15%]">
      <img 
      className="w-full xs:w-auto"
      src={isMobile ? 'https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693572959/WALLET%20BLACK/Landing%20beneficios/Hero/Rosario/Group_519_ls7d66.svg' : 
      'https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693505664/WALLET%20BLACK/Landing%20beneficios/Hero/Rosario/CLUB_ATL%C3%89TICO_ROSARIO_CENTRAL_mhmpqf.svg'} />
    
       {isMobile ? null : 
       <p className="text-white text-[15px] lt:text-[19px] leading-5 mt-[8%] w-[100%] lt:w-[75%]">
         ¡Canalla! Tenemos beneficios exclusivos para vos, ¿Querés conocerlos? Suscribite y enterate de todos los descuentos y promociones que podes tener siendo parte de la Comunidad Black
        </p>} 
    </div>
  </div>

 {isMobile ? 
    <div className=" flex justify-center items-center">
    <p className="text-white text-[16px] font-medium  leading-5 mt-[5%] w-[80%] ">  ¡Canalla! Tenemos beneficios exclusivos para vos, ¿Querés conocerlos? Suscribite y enterate de todos los descuentos y promociones que podes tener siendo parte de la Comunidad Black
        </p>
     </div> 
        : null}
 
   <Formulario  title={'¡Unite a la comunidad de Rosario!'} Comunidad={'Rosario'}/>


</div>
 
         <Comunidad camiseta='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693581813/WALLET%20BLACK/Landing%20beneficios/Comunidad/banfield/redimension_Mesa_de_trabajo_1_copia_1_mec3eu.png'
          entrada='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693581990/WALLET%20BLACK/Landing%20beneficios/Comunidad/banfield/entradas3d-_mockup-01_osnflz.png'
          top={'top-[-24%] xs:top-[-25%] lt:top-[-35%] ltl:top-[-50%] md:top-[-45%] xl:top-[-54%]'}
         />
     
     <Footer2/>
   </div>

    )

}


export default Rosario