
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import Home from './Pages/Home/Home';
import Banfield from './Pages/Clubes/Banfield';
import Rosario from './Pages/Clubes/Rosario';
import Santamarina from './Pages/Clubes/Santamarina';
import Black from './Pages/Clubes/Black';

function App() {
  return (
      <BrowserRouter>
       <Routes className='bg-black'>
            <Route path='/' element={<Home />} /> 
            <Route path='/banfield' element={<Banfield/>}/>
            <Route path='/rosario' element={<Rosario/>} />
            <Route path='/santaMarina' element={<Santamarina/>} />
            <Route path='/black' element={<Black/>} />
       </Routes>
      
      </BrowserRouter>

  );
}

export default App;
