
import { useMediaQuery } from "react-responsive";
import Formulario from "../Form";


const   Hero = ({descripcion,titulo,logo,titleForm}) => {

  const isMobile = useMediaQuery({ maxWidth: 480 }); // Para solo smartphones

    return (
     <div className="bg-black flex animate-fadeInDown  flex-col xs:flex-row">
       <div className="w-full xs:w-[50%]  relative  flex justify-center  items-start ">
         <img
           className="w-[38%] pr-[3%] pt-[4%]  xs:w-[60%] lt:w-[45%] xs:pt-[10%] lt:pt-[3%] xs:pr-[10%]"
           src={titulo}
         />

         <div className="absolute top-[20%] left-[11%] w-[75%] xs:w-auto xs:top-[25%] xs:left-[15%]">
           <img 
           className="w-full xs:w-auto"
           src={isMobile ? 'https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693508703/WALLET%20BLACK/Landing%20beneficios/Hero/Group_519_ygznln.svg' : logo} />
         
            {isMobile ? null : 
            <p className="text-white text-[15px] lt:text-[19px] leading-5 mt-[8%] w-[100%] lt:w-[75%]">
             {descripcion}
             </p>} 
         </div>
       </div>

      {isMobile ? 
         <div className=" flex justify-center items-center">
         <p className="text-white text-[16px]  leading-5 mt-[5%] w-[80%] font-medium ">Ser de El Taladro ahora tiene beneficios exclusivos, suscribite a
             la Comunidad Black y enterate de todos los descuentos y promociones
             que tenemos para vos!
             </p>
          </div> 
             : null}
       
       <Formulario title={'¡Unite a la comunidad de Banfield!'}  Comunidad={'Banfield'}/>
     
     </div>
   );

}


export default Hero