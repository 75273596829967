import { useMediaQuery } from "react-responsive"

const Comunidad = ({camiseta,entrada,top}) => {

  const isMobile = useMediaQuery({maxWidth:480})

  return (
    <div className="bg-black">
          
        
        
         {isMobile ? 
         <div className="w-full flex mb-[10%] justify-center">
         <img className="w-[55%] xs:w-[35%] xs:ml-[5%] mb-[8%]" 
        src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1694634844/WALLET%20BLACK/Landing%20beneficios/Equipos/Group_540_tty2tm.png' alt='comunidad'/>
        </div>
               
        :
        <img className="w-[80%] xs:w-[35%] ml-[5%] mb-[8%]" 
        src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1694631719/WALLET%20BLACK/Landing%20beneficios/Equipos/Group_551_eluhf1.png' alt='comunidad'/>}
         
          
      



    <div className="w-full flex justify-center">
       
         <div className={`flex flex-col ${isMobile ? 'items-center' : ''}   xs:flex-row justify-center mb-[5%] space-y-[25%] xs:space-y-0  xs:space-x-4 w-[80%]`}>
          

             <div className="w-[85%] xs:w-[28%] lt:w-[24%] md:w-[15%] border flex justify-center pt-[40%] pb-12 xs:py-0 relative items-end  rounded-xl">
                  <img className={`absolute ${top} w-[60%] xs:w-[100%] sm:w-[70%] lt:w-[80%] md:w-[100%]`}
                   src={camiseta} alt='camiseta'/>
                  <p className="text-white w-[65%] xs:w-[75%] xs:mb-[12%] xs:text-[15px] lt:text-[18px]  text-center font-medium">Obtené un descuento en la compra de tu camiseta</p>
             </div>

          
             <div className="w-[85%] xs:w-[40%] lt:w-[35%] md:w-[25%] border relative flex justify-center pt-[30%] xs:pt-0 pb-8 xs:pb-0 items-end rounded-xl">
             <img className="absolute top-[-40%] xs:top-[-35%] sm:top-[-45%] lt:top-[-35%] ltl:top-[-60%] md:top-[-44%] xl:top-[-70%] w-[70%] xs:w-[100%] sm:w-[70%] lt:w-[70%]  md:w-[85%]"
                   src={entrada} 
                   alt='camiseta'/>
              
                   <p className="text-white xs:text-[15px] lt:text-[18px] w-[70%] xs:mb-[16%] text-center font-medium">Conseguí descuentos en la compra de tus entradas</p>
             </div>


             <div className="w-[85%] xs:w-[40%] lt:w-[35%] md:w-[25%] border relative  flex justify-center items-end px-8   xs:px-12 pt-16 xs:pt-20 pb-4 xs:pb-12 rounded-xl">  
             <img className="absolute se:top-[-32%] spro:top-[-42%] xs:top-[-52%] ltl:top-[-65%] md:top-[-70%] xl:top-[-85%] w-[55%] xs:w-[70%] sm:w-[50%]  lt:w-[55%] md:w-[70%]"
                   src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693583182/WALLET%20BLACK/Landing%20beneficios/Comunidad/banfield/carrito_saliendo_de_celu_onyvbm.png' 
                   alt='celu'/>
                     <p className="text-white xs:text-[15px] lt:text-[18px] text-center  font-medium">
                        Disfrutá los mejores descuentos en la tienda oficial y
                         aprovechá las promociones que tenemos en gastromía, indumentaria y mucho más!</p>
             </div>

         </div>
       
    </div>

    </div>
  )


}



export default Comunidad