

import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";


const Formulario = ({title,Comunidad}) => {
    const isMobile = useMediaQuery({ maxWidth: 480 }); // Para solo smartphones

    const [formData,setFormData] = useState({
        email:'',
        contraseña:'',
        Comunidad:Comunidad
    })
    const [isOpen, setIsOpen] = useState(false)
    const [errors, setErrors] = useState({});
    const [showErrors,setShowErrors] = useState(false)
    const [isClosing, setIsClosing] = useState(false)
    const [visible, setVisible] = useState(false)


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value
        }));
    }
   
    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.keys(errors).length > 0) {
            setShowErrors(true); 
        } else {
            console.log(formData,'submiteado')
            setIsOpen(true)
            setFormData({
                email:'',
                contraseña:'',
            })
            setShowErrors(false)
        } 
        
    }

    useEffect( () => {
        setErrors(validate(formData));
    },[formData])

    const validate = (formData) => {
        let errors = {};
      
        if (!formData.email) {
          errors.email = 'El correo electrónico es requerido';
        }else {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(formData.email)) {
            errors.email2 = 'El correo electrónico no es válido';
          }
        }
       
        if(!formData.contraseña) {
            errors.contraseña = 'Ingrese una contraseña'
        }
           
        return errors;
      }

      
      const handleLogout = () => {
        setIsClosing(true);
        setTimeout(() => {
          setIsOpen(false);
          setIsClosing(false)
        }, 350); // Espera 0.5 segundos para ocultar el componente después de la animación
      }; 
  


      return (
        <div className="w-full xs:w-[50%] flex flex-col items-center justify-center ">
    
        <form onSubmit={handleSubmit} className=" w-[85%] lt:w-[70%] relative mt-12 mb-12 p-8 lt:p-14 text-white border rounded-xl">
          <h1 className="text-[25px] lt:text-[38px] mb-3 font-bold">
          {title}
          </h1>
          <h3 className='font-medium'>
            Completá el formulario con los datos usados en tu cuenta Black
          </h3>
  
          <div className="flex flex-col z-[100] w-full  mt-8 space-y-7">
            <div className="relative z-[100]">
              <label className="absolute bg-black top-[-0.5rem] left-[12px] px-1 text-[13px]">
                Mail registrado con black
              </label>
              <input onChange={handleInputChange}
                placeholder="Mail registrado en black |"
                name="email"
                value={formData.email}
                className="bg-black z-[100] placeholder:font-[500] w-full xs:w-[75%] lt:w-[60%] border border-white rounded-lg pb-2 p-3"
              />
            {showErrors ? errors.email && (<p style={{color:'red', fontSize:'10px'}}>{errors.email}</p>): null}
            {showErrors ? errors.email2 && (<p style={{color:'red', fontSize:'10px'}}>{errors.email2}</p>): null}
            </div>
  
            <div className="mb-12  z-[100] relative">
              <label className="absolute bg-black top-[-0.5rem] left-[12px] px-1 text-[13px]">
                Contraseña
              </label>
              <input
                onChange={handleInputChange}
                placeholder="Contraseña |"
                name="contraseña"
                type={visible? "text" : "password"}
                value={formData.contraseña}
                className="bg-black z-[100] placeholder:font-[500]  w-full xs:w-[75%] lt:w-[60%] border border-white rounded-lg pb-2 p-3"
                />
                {showErrors ? errors.contraseña && (<p style={{color:'red', fontSize:'10px'}}>{errors.contraseña}</p>): null}
                <img onClick={() => setVisible(!visible)} 
                className="absolute w-8 h-8 top-2 left-[80%] xs:left-[61%] lt:left-[47%]  md:left-[51%] cursor-pointer" src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1695308293/WALLET%20BLACK/Trailing_Icon_omjidu.svg' /> 
            </div>
          </div>
           
           <div className="w-full mt-[9%]  flex justify-end">
           <button type="submit" className="bg-[#3C3C3C] p-3 w-[35%] xs:w-[25%] md:w-[17%] hover:bg-[#16AC7D] transition-all duration-300 rounded-3xl">Enviar</button>
           </div>
           
           <img className="absolute left-0 z-[10] bottom-0" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693502010/WALLET%20BLACK/Landing%20beneficios/Hero/Capa_1_kyybap.svg"  alt='bg'/>
  
        </form>
  
        
  
        <div className=" flex flex-col justify-center mb-[10%] items-center w-full">
             <p className="text-white text-[19px] xs:text-[18px] font-[500] text-center w-[80%] xs:w-auto mb-5">
               Si todavía no sos #Blacker, descargate la app
             </p>
  
             <div className="flex flex-col xs:flex-row justify-center items-center space-y-4 xs:space-y-0 xs:space-x-2  w-full">
              
              <a target='blank' 
               href={isMobile ? 'https://plataforma.blackwallet.com.ar/landing/share.html' : 
               'https://play.google.com/store/apps/details?id=app.blackwallet.ar&hl=es_419'} className="w-[55%] xs:w-[28%] lt:w-[20%]">
               <img className='w-full'
                 src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693493591/WALLET%20BLACK/Landing%20beneficios/Hero/Group_dqehwc.svg"
                 alt="google"
                 />
              </a>
               
              <a target='blank'  className="w-[55%] xs:w-[28%] lt:w-[20%]" 
              href={isMobile ? 'https://plataforma.blackwallet.com.ar/landing/share.html' : 'https://apps.apple.com/ph/app/black-wallet/id1661314614?uo=2'}>
               <img className='w-full'
                 src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693493684/WALLET%20BLACK/Landing%20beneficios/Hero/appStore_proyaz.svg"
                 alt="google"
               />
             </a>
  
             </div>
           </div>
  

           {isOpen && ( <div className='bg-black modal-container font-Ubuntu'>
  <div className={`modal border ${isClosing ? 'slide-out' : 'slide-in'} border-white`}>
    <button className='w-full flex justify-end pr-4 mt-2' onClick={() => handleLogout()}>x</button>
    <div className='flex flex-col px-16 py-12 justify-center items-center'>
      <div className='bg-bgButton rounded-full w-[4.2rem] h-[4.2rem] flex items-center justify-center'>
        <div className="bg-gray-300 w-20 h-16 rounded-full"> a</div>
       </div> 
     <h1 className='font-bold text-[30px] xs:text-[40px]'>Te suscribiste a la comnunidad de {Comunidad}</h1>
     <h3>A la brevedad nos pondremos en contacto.</h3>
   
   </div>
  </div> 


</div>) }

      </div>
      )      


}


export default Formulario