

import { Form, Link } from 'react-router-dom'
import Hero from '../../Components/Clubes/Hero'
import venus from '../../Venus.svg'
import { useMediaQuery } from 'react-responsive'
import Comunidad from '../../Components/Comunidad'
import '../../App.css'
import { useState } from 'react'
import Footer from '../../Components/Footer'
import Footer2 from '../../Components/Footer2'
import Formulario from '../../Components/Form'

const Black = () => {
 
const [selected,setSelected] = useState('')

 
  const isMobile = useMediaQuery({ maxWidth: 480 }); // Para solo smartphones

  return (
       
        <div className='bg-black'>

                <Link to='/' className='bg-black z-[100] py-10 flex top-0  w-full justify-center'>
                <img src={venus} className=' w-[25%] xs:w-[8%]'/>
                </Link>
             

                <div className="bg-black flex animate-fadeInDown  flex-col xs:flex-row">
    
    <div className="w-full xs:w-[50%]  relative  flex justify-center  items-start ">
      <img
        className="w-[25%] pr-[2%] pt-[0%]  xs:w-[32%]  xs:pt-[10%] lt:pt-[3%] xs:pr-[10%]"
        src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693506441/WALLET%20BLACK/Landing%20beneficios/Hero/Black/Group_542_hedyuh.svg'
        />

      <div className="absolute top-[24%] left-[16%] w-[85%] xs:w-auto xs:top-[18%] xs:left-[15%]">
        <img 
        className="w-full xs:w-auto"
        src={isMobile ? 'https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693506310/WALLET%20BLACK/Landing%20beneficios/Hero/Black/Group_551_snucyu.svg' : 
        'https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693506310/WALLET%20BLACK/Landing%20beneficios/Hero/Black/Group_551_snucyu.svg'} />
      
         {isMobile ? null : 
         <p className="text-white text-[15px] lt:text-[19px] leading-5 mt-[8%] w-[100%] lt:w-[75%]">
          No es necesario que seas hincha de un equipo, nosotros te alentamos a vos! Acá vas a encontrar todos los beneficios que tenés siendo Blacker, suscribite y disfrutá!
          </p>} 
      </div>
    </div>

   {isMobile ? 
      <div className=" flex justify-center items-center">
      <p className="text-white text-[16px] font-[500]  leading-5 mt-[5%] w-[80%] ">No es necesario que seas hincha de un equipo, nosotros te alentamos a
       vos! Acá vas a encontrar todos los beneficios que tenés siendo Blacker, suscribite y disfrutá!
          </p>
       </div> 
          : null}
   
    <Formulario  title={' ¡Unite a la comunidad de Black!'} Comunidad='Black'/>
    
  </div>
      
{/* CATEGORIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAS */}
         
          <img className='se:w-[63%] spro:w-[55%] se:ml-[19%] spro:ml-[22%] mb-[25%] xs:w-[20%] xs:mb-[2%] xs:ml-[6%]'  
          src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1694631923/WALLET%20BLACK/Landing%20beneficios/Equipos/Categor%C3%ADas_tukqst.png' alt='categorias' /> 
  
   <div className='flex flex-col'>

     <div className='flex flex-col xs:flex-row w-full justify-center pb-[2%]  xs:space-y-0 xs:space-x-6 items-center'>               
             
        {/* GASTRONOMIAAAA */}
            {selected !='' && selected !='gastronomia' ? 
            
            <div  onClick={() => setSelected('gastronomia')} className='border mb-[20%] xs:mb-0 hover:cursor-pointer border-white z-[1000] 
            h-[30vh] xs:h-[40vh] w-[60%] xs:w-[25%] lt:w-[18%] rounded-xl flex justify-center items-center'>
               
               
                 <div className='flex w-full border rounded-xl justify-end xs:justify-center relative items-center h-[30vh] xs:h-[40vh] blar flex-col'>
                        
                        <div className='w-[90%] top-[-40%] xs:w-[70%] xs:static absolute xs:h-[65%] mb-6'>
                         <img className=''
                         src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693829926/WALLET%20BLACK/Landing%20beneficios/Categorias/saya_belum_selesai1_cgxof7.png'  alt='Gastro' />
                         </div>
                         
                          <h1 className='text-white se:pb-[1rem] spro:pb-[2rem] xs:pb-[4.5rem] font-[500] text-[21px]'>Gastronomía</h1>
                   </div>
            </div>
            :  
            
            <div onClick={selected == 'gastronomia' ? () => setSelected('') : () => setSelected('gastronomia')}
             className={`flex border relative xs:static  ${selected !='gastronomia' ? 'mb-[20%]' : 'mb-[7%]'} xs:mb-0  hover:cursor-pointer rounded-xl justify-end xs:justify-center items-center 
            h-[30vh] xs:h-[40vh]  w-[60%] xs:w-[25%] lt:w-[18%] flex-col`}>
                        
                        <div className='w-[90%] xs:w-[70%] absolute xs:static top-[-35%] xs:top-0  h-[65%] mb-6'>
                         <img className=''
                         src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693829926/WALLET%20BLACK/Landing%20beneficios/Categorias/saya_belum_selesai1_cgxof7.png'  alt='Gastro' />
                         </div>
                         
                          <h1 className='text-white se:pb-[1rem] spro:pb-[2rem] xs:pb-[4.5rem] font-[500] text-[21px]'>Gastronomía</h1>
                   </div>
            } 
             
            {isMobile && selected ==='gastronomia' ? 
            
            <div className='flex flex-col items-center space-y-5 animate-fadeInDown pb-[5%]'>
              

                  <div className='border w-[45%] flex justify-center items-center rounded-xl h-[22vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693839304/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/mercado_kcyuk1.svg' 
                        className='w-[60%] h-[60%]'/>
                  </div>    


                    <div className='border w-[45%] flex justify-center items-center rounded-xl h-[22vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693839330/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/Logo_Rey_del_Dulce-01_ill4z8.svg' 
                        className='w-[55%] h-[55%]'/>
                  </div>    


                  <div className='border w-[45%] flex justify-center items-center rounded-xl h-[22vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693839377/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/temple_new_1_frgcbb.svg' 
                        className='w-[60%] h-[60%]'/>
                  </div>           

              

                  <div className='border w-[45%] flex justify-center items-center rounded-xl h-[22vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693840636/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/masse_new_2_f6fccp.svg' 
                        className='w-[65%] h-[65%]'/>
                  </div>    


                    <div className='border w-[45%] flex justify-center items-center rounded-xl h-[22vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693840706/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/nina_zinoss.svg' 
                        className='w-[75%] h-[75%]'/>
                  </div>    


                  <div className='border w-[45%] flex justify-center items-center rounded-xl h-[22vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693840817/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/baxar_cbmjda.svg' 
                        className='w-[75%] h-[75%]'/>
                  </div>           

              

              
                   
                     <div className='border rounded-xl flex items-center justify-center w-[45%] h-[22vh]'>
                     <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693841129/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/paesano_pguto7.svg' 
                        className='w-[75%] h-[75%]'/>
                    </div>
                    
                    <div className='border rounded-xl flex items-center justify-center w-[45%] h-[22vh]'>
                    <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693841214/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/perla_lfftt0.svg' 
                        className='w-[75%] h-[75%]'/>
                    </div>
              
           

              
                   
                   <div className='border rounded-xl flex items-center justify-center w-[45%] h-[22vh]'>
                   <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693841257/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/Esquina_Diagonal-01_hq77up.svg' 
                      className='w-[68%] h-[68%]'/>
                  </div>
                  
                  <div className='border rounded-xl flex items-center justify-center w-[45%] h-[22vh]'>
                  <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693841283/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/runnies_1_1_icfvxg.svg' 
                      className='w-[75%] h-[75%]'/>
                  </div>
            
           
            </div>
            
            : 
            
            null} 
             
                  

              {/*HOTELERIAAA */}


              {selected != '' && selected !='hoteleria' ? 
               <div onClick={selected == 'hoteleria' ? () => setSelected('') : () => setSelected('hoteleria')} className=' hover:cursor-pointer border border-white z-[1000]
               h-[20vh] xs:h-[40vh] w-[60%] mb-[20%] xs:w-[25%] lt:w-[18%] rounded-xl flex justify-end xs:justify-center items-center'>
              
              <div className='flex rounded-xl justify-center items-center h-[40vh] w-full blar flex-col'>
                
                   <div className='xs:w-[70%] h-[62%] absolute xs:static top-[-2%] xs:top-0 flex justify-center items-center  mb-6'>
                         <img className='w-[58%]'
                         src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693831017/WALLET%20BLACK/Landing%20beneficios/Categorias/viaje_1_tptpcc.svg'  alt='Gastro' />
                   </div>
                          <h1 className='text-white pt-[4rem] xs:pt-[0] xs:pb-10 font-[500] text-[21px]'>Hotelería</h1>
                   </div>
               </div>    
               :
               
            <div onClick={selected == 'hoteleria' ? () => setSelected('') :() => setSelected('hoteleria')} 
            className={`flex border relative xs:static  hover:cursor-pointer rounded-xl justify-end xs:justify-center items-center 
            h-[20vh] xs:h-[40vh] ${selected !='hoteleria' ? 'mb-[17%]' : 'mb-[7%]'} w-[60%] xs:w-[25%] lt:w-[18%] flex-col`}>
              
               <div className=' xs:w-[70%] h-[62%] absolute xs:static top-[-25%] xs:top-0 flex justify-center items-center  mb-6'>
                     <img className='w-[58%]'
                     src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693831017/WALLET%20BLACK/Landing%20beneficios/Categorias/viaje_1_tptpcc.svg'  alt='Gastro' />
               </div>
                      <h1 className='text-white se:pb-2 spro:pb-10 font-[500] text-[21px]'>Hotelería</h1>
               </div>
            }
                   

                   {
           isMobile && selected === 'hoteleria' ? 
           <div className='flex flex-col animate-fadeInDown pb-[5%]'>
          
               <div className='flex flex-col items-center space-y-6 mt-[2%]  justify-center'>
                   
                     <div className='border rounded-xl flex items-center justify-center w-[45%] h-[22vh]'>
                     <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693842607/WALLET%20BLACK/Landing%20beneficios/Categorias/hoteleria/Group_547_bdpskr.svg' 
                        className='w-[75%] h-[75%]'/>
                    </div>
                    
                    <div className='border rounded-xl flex items-center justify-center w-[45%] h-[22vh]'>
                    <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693842626/WALLET%20BLACK/Landing%20beneficios/Categorias/hoteleria/Days_Inn-01_v8n1sz.svg' 
                        className='w-[64%] h-[64%]'/>
                    </div>
              
              </div>

            </div>
            :
            
             null
          }

 


                
                {/* RECREACION */}
                 {selected !='' && selected !='recreacion'? 
                 
                <div onClick={selected == 'recreacion' ? () => setSelected('') : () => setSelected('recreacion')} 
                className='hover:cursor-pointer border border-white z-[1000] h-[30vh] xs:h-[40vh] 
                w-[60%] xs:w-[25%] lt:w-[18%] rounded-xl flex justify-center items-center'>

                 <div className='flex rounded-xl justify-center items-center h-[40vh] w-full blar flex-col'>
                   
                   <div className='w-full h-full z-[100] absolute rounded-xl '> </div>
                
                   <div className='w-[85%] xs:w-[70%] h-[65%] flex z-[10] justify-center items-center mb-6'>
                       <img className='w-[75%] '
                       src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693831148/WALLET%20BLACK/Landing%20beneficios/Categorias/PELOta_2_dkuiyf.svg'  alt='Gastro' />
                 </div>
                        <h1 className='text-white pb-16  font-[500] z-[10]  text-[25px]'>Recreación</h1>
                 </div> 
                </div> 
                 
                 :  
                 
                 
                 
                 <div onClick={selected == 'recreacion' ? () => setSelected('') :  () => setSelected('recreacion')} 
                 className={`hover:cursor-pointer flex rounded-xl border relative justify-end xs:justify-center items-center bg-black h-[30vh] xs:h-[40vh] 
                 w-[60%] xs:w-[25%] lt:w-[18%] flex-col`}>
                   
                   <div className='w-full h-full z-[100] absolute rounded-xl '> </div>
                
                   <div className='w-[85%] xs:w-[70%] h-[65%] absolute xs:static top-[-20%] flex z-[10] justify-center items-center mb-6'>
                       <img className='w-[75%] '
                       src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693831148/WALLET%20BLACK/Landing%20beneficios/Categorias/PELOta_2_dkuiyf.svg'  alt='Gastro' />
                 </div>
                        <h1 className='text-white se:pb-10 2 spro:pb-16 xs:pb-10 md:pb-16 font-[500] z-[10]  text-[21px]'>Recreación</h1>
                 </div>}

          </div> 
          
          {
          isMobile &&  selected === 'recreacion' ? 
           <div className='flex flex-col animate-fadeInDown pb-[5%]'>
              <div className='flex flex-col items-center my-[10%] space-y-6 justify-center'>

                  <div className='border flex justify-center items-center rounded-xl w-[45%] h-[22vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693842835/WALLET%20BLACK/Landing%20beneficios/Categorias/recreacion/San_Luis_nki1kn.svg' 
                        className='w-[60%] h-[60%]'/>
                  </div>    


                    <div className='border  flex justify-center items-center rounded-xl w-[45%] h-[22vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693842861/WALLET%20BLACK/Landing%20beneficios/Categorias/recreacion/La_Plata_Rugby_Club_mwwjbs.svg' 
                        className='w-[55%] h-[55%]'/>
                  </div>    


                  <div className='border  flex justify-center items-center rounded-xl w-[45%] h-[22vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693842923/WALLET%20BLACK/Landing%20beneficios/Categorias/recreacion/logo_gr_1_eraoej.svg' 
                        className='w-[60%] h-[60%]'/>
                  </div>           

               </div>  
            </div>
            :
            
             null
          }



          {
           !isMobile && selected === 'gastronomia' ? 
           <div className='flex flex-col animate-fadeInDown pb-[5%]'>
              <div className='flex flex-row mb-[2%] space-x-6 justify-center'>

                  <div className='border w-[18%] flex justify-center items-center rounded-xl h-[30vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693839304/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/mercado_kcyuk1.svg' 
                        className='w-[60%] h-[60%]'/>
                  </div>    


                    <div className='border w-[18%] flex justify-center items-center rounded-xl h-[30vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693839330/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/Logo_Rey_del_Dulce-01_ill4z8.svg' 
                        className='w-[55%] h-[55%]'/>
                  </div>    


                  <div className='border w-[18%] flex justify-center items-center rounded-xl h-[30vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693839377/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/temple_new_1_frgcbb.svg' 
                        className='w-[60%] h-[60%]'/>
                  </div>           

               </div>  



               <div className='flex flex-row space-x-6  justify-center'>

                  <div className='border w-[18%] flex justify-center items-center rounded-xl h-[30vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693840636/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/masse_new_2_f6fccp.svg' 
                        className='w-[65%] h-[65%]'/>
                  </div>    


                    <div className='border w-[18%] flex justify-center items-center rounded-xl h-[30vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693840706/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/nina_zinoss.svg' 
                        className='w-[75%] h-[75%]'/>
                  </div>    


                  <div className='border w-[18%] flex justify-center items-center rounded-xl h-[30vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693840817/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/baxar_cbmjda.svg' 
                        className='w-[75%] h-[75%]'/>
                  </div>           

               </div>  

               <div className='flex flex-row space-x-6 mt-[2%]  justify-center'>
                   
                     <div className='border rounded-xl flex items-center justify-center w-[28%] h-[30vh]'>
                     <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693841129/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/paesano_pguto7.svg' 
                        className='w-[75%] h-[75%]'/>
                    </div>
                    
                    <div className='border rounded-xl flex items-center justify-center w-[28%] h-[30vh]'>
                    <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693841214/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/perla_lfftt0.svg' 
                        className='w-[75%] h-[75%]'/>
                    </div>
              
              </div>

              <div className='flex flex-row space-x-6 mt-[2%]  justify-center'>
                   
                   <div className='border rounded-xl flex items-center justify-center w-[28%] h-[30vh]'>
                   <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693841257/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/Esquina_Diagonal-01_hq77up.svg' 
                      className='w-[68%] h-[68%]'/>
                  </div>
                  
                  <div className='border rounded-xl flex items-center justify-center w-[28%] h-[30vh]'>
                  <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693841283/WALLET%20BLACK/Landing%20beneficios/Categorias/Gastronomia/runnies_1_1_icfvxg.svg' 
                      className='w-[75%] h-[75%]'/>
                  </div>
            
            </div>
            </div>
            :
            
             null
          }
           

           {
           !isMobile && selected === 'hoteleria' ? 
           <div className='flex flex-col animate-fadeInDown pb-[5%]'>
          
               <div className='flex flex-row space-x-6 mt-[2%]  justify-center'>
                   
                     <div className='border rounded-xl flex items-center justify-center w-[28%] h-[30vh]'>
                     <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693842607/WALLET%20BLACK/Landing%20beneficios/Categorias/hoteleria/Group_547_bdpskr.svg' 
                        className='w-[75%] h-[75%]'/>
                    </div>
                    
                    <div className='border rounded-xl flex items-center justify-center w-[28%] h-[30vh]'>
                    <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693842626/WALLET%20BLACK/Landing%20beneficios/Categorias/hoteleria/Days_Inn-01_v8n1sz.svg' 
                        className='w-[64%] h-[64%]'/>
                    </div>
              
              </div> 

            </div>
            :
            
             null
          }



{
          !isMobile &&  selected === 'recreacion' ? 
           <div className='flex flex-col animate-fadeInDown pb-[5%]'>
              <div className='flex flex-row mb-[2%] space-x-6 justify-center'>

                  <div className='border xs:w-[25%] lt:w-[18%] flex justify-center items-center rounded-xl h-[30vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693842835/WALLET%20BLACK/Landing%20beneficios/Categorias/recreacion/San_Luis_nki1kn.svg' 
                        className='w-[60%] h-[60%]'/>
                  </div>    


                    <div className='border xs:w-[25%] lt:w-[18%] flex justify-center items-center rounded-xl h-[30vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693842861/WALLET%20BLACK/Landing%20beneficios/Categorias/recreacion/La_Plata_Rugby_Club_mwwjbs.svg' 
                        className='w-[55%] h-[55%]'/>
                  </div>    


                  <div className='border xs:w-[25%] lt:w-[18%] flex justify-center items-center rounded-xl h-[30vh]'>
                       <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693842923/WALLET%20BLACK/Landing%20beneficios/Categorias/recreacion/logo_gr_1_eraoej.svg' 
                        className='w-[60%] h-[60%]'/>
                  </div>           

               </div>  
            </div>
            :
            
             null
          }

    </div>
      <Footer2 />
        </div>

    )

}


export default Black