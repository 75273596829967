

import google from '../google.svg'
import appstore from '../appStore.svg'
import youtube from '../Vector-1.svg'
import tiktok from '../Vector-2.svg'
import facebook from '../Vector.svg'
import instagram from '../Group.svg'
import X from '../layer1.svg'
import { useMediaQuery } from "react-responsive";


const Footer = () => {
   
   const isMobile = useMediaQuery({maxWidth:480})

     return (
        <div className="flex flex-col xs:flex-row bg-black h-[20vh] pb-32 xs:pb-0 xs:mt-0 pt-8  items-center">
         
            <div className="flex w-[50%] z-[100] flex-row justify-center xs:justify-start xs:pl-[12%] space-x-2">
             <a target='blank' 
             href={isMobile ? 'https://plataforma.blackwallet.com.ar/landing/share.html' : 
             'https://play.google.com/store/apps/details?id=app.blackwallet.ar&hl=es_419'}> <img src={google}  alt='google'/>   </a>   
            
            <a target='blank' 
            href={isMobile ? 'https://plataforma.blackwallet.com.ar/landing/share.html' : 'https://apps.apple.com/ph/app/black-wallet/id1661314614?uo=2'}> <img src={appstore} alt='appstore'/>  </a> 
            </div>
         
           <div className='flex flex-row justify-center mt-4  xs:justify-end w-[50%] z-[100] xs:pr-[12%] space-x-3'>
                
                <a href='https://www.facebook.com/blackwallet.ar' target='blank'> <img src={facebook} className='w-6 h-6'/> </a>
               <a href='https://www.instagram.com/blackwallet.ar/' target='blank'> <img src={instagram} className='w-6 h-6'/> </a> 
               <a href='https://twitter.com/Blackwallet_ar' target='blank'> <img src={X} className='w-6 h-6'/> </a> 
               <a href='https://www.youtube.com/@blackwallet_ar' target='blank'> <img src={youtube} className='w-6 h-6'/></a>
               <a href='https://www.tiktok.com/@blackwallet.ar' target='blank'> <img src={tiktok} className='w-6 h-6'/> </a>
           </div>
            

        </div>
     )
}


export default Footer