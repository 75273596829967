import Footer from "../../Components/Footer";
import venus from "../../Venus.svg";
import black from "../../black.svg";
import rosario from "../../asstes/Capa_1.svg";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Home = () => {
 const isMobile = useMediaQuery({maxWidth:480})
 const ScrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'auto'
  })
}



  return (
    <div className="bg-black">
      <div className=" flex flex-col  bg-black">
       
       {isMobile ? <img
          className="absolute top-[8%] z-[100]"
          src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693574618/WALLET%20BLACK/Landing%20beneficios/Hero/ElementosHome_mobile_fjibv9.png"
        />
       
      : <img
      className="absolute z-[0]"
      src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693425165/WALLET%20BLACK/Landing%20beneficios/ElementosHome_zegyym.png"
    />}
        

      
          <div className="bg-black  z-[100] py-10 flex top-0  w-full justify-center">
          <img src={venus} className=" w-[25%] xs:w-[10%] md:w-[8%]" />
          </div>
      

        <div className="flex justify-center animate-fadeInDown xs:mt-[6%] h-[40vh] xs:h-[28vh]  items-center flex-col">
          <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693854150/WALLET%20BLACK/Landing%20beneficios/Equipos/black_z7n0h3.svg' className="w-[70%] z-[250] xs:w-[28%] md:w-[23%]" />
          <h1 className="font-ubuntu text-[#FFFFFF] font-[500] mt-3 xs:mt-6 xs:text-[22px]">
            vos alentá,nosotros te bancamos
          </h1>
        </div>

        <div className="flex flex-col animate-fadeInDown  xs:flex-row z-[100] space-y-12 xs:space-y-0 xs:space-x-4 mt-8 mb-12 items-center justify-center">
         
      
         <Link to='/rosario' onClick={() => ScrollToTop()} className="w-[70%] xs:w-[22%] lt:w-[15%] bg-black  z-[200] lt:max-w-[15%] border text-white flex-col py-5 px-3  justify-center items-center flex rounded-xl">
            <div className=" flex  justify-center w-full">
              <img className="w-[28%] mb-3 mt-3" src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693854074/WALLET%20BLACK/Landing%20beneficios/Equipos/Group_505_vdky45.svg' />
            </div>
            <h1 className="text-[#FFFFFF] mb-5 text-[22px] font-[500]">
              Rosario Central
            </h1>
            <h3 className="w-[58%] xs:w-[80%] lt:w-[58%] se:text-[17px] xs:text-[15px] md:text-[17px] leading-5 text-center">
              Quiero ser parte de esta comunidad
            </h3>
          </Link>

          <Link to='/banfield'  onClick={() => ScrollToTop()} className="border w-[70%] xs:w-[22%] lt:w-[15%] bg-black  z-[200] lt:max-w-[15%]  text-white flex-col py-5 px-3  justify-center items-center flex rounded-xl">
            <div className=" flex justify-center w-full">
              <img
                className="w-[20%] mb-3 mt-3"
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693485507/WALLET%20BLACK/Landing%20beneficios/Equipos/banfield_1_rjabda.svg"
              />
            </div>
              <h1 className="text-[#FFFFFF] mb-5 text-[22px] font-[500]">
                Banfield
              </h1>
        
            <h3 className="w-[58%] xs:w-[80%] lt:w-[58%] se:text-[17px] xs:text-[15px] md:text-[17px] leading-5 text-center">
              Quiero ser parte de esta comunidad
            </h3>
          </Link>

          <Link to='/santaMarina'  onClick={() => ScrollToTop()} className="w-[70%]  xs:w-[22%] lt:w-[15%] bg-black  z-[200] lt:max-w-[15%] border text-white flex-col py-5 px-3  justify-center items-center flex rounded-xl">
            <div className=" flex justify-center w-full">
              <img
                className="w-[28%]  mb-3 mt-3"
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693485507/WALLET%20BLACK/Landing%20beneficios/Equipos/Modo_de_aislamiento_nvrhmc.svg"
              />
            </div>
            <h1 className="text-[#FFFFFF] mb-5 text-[22px] font-[500]">
              SantaMarina
            </h1>
            <h3 className="w-[58%] xs:w-[80%] lt:w-[58%] se:text-[17px] xs:text-[15px] md:text-[17px] leading-5 text-center">
              Quiero ser parte de esta comunidad
            </h3>
          </Link>

          <Link to='/black'  onClick={() => ScrollToTop()} className="w-[70%] xs:w-[22%] lt:w-[15%] bg-black  z-[200] lt:max-w-[15%] border text-white flex-col py-5 px-3  justify-center items-center flex rounded-xl">
            <div className=" flex justify-center w-full">
              <img
                className="w-[13%]  mb-3 mt-3"
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693485630/WALLET%20BLACK/Landing%20beneficios/Equipos/Group_542_jzxc3u.svg"
              />
            </div>
            <h1 className="text-[#FFFFFF] mb-5 text-[22px] font-[500]">
              Black
            </h1>
            <h3 className="w-[58%] xs:w-[80%] lt:w-[58%] se:text-[17px] xs:text-[15px] md:text-[17px] leading-5 text-center">
              Quiero ser parte de esta comunidad
            </h3>
          </Link>
       
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
